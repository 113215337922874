// 根据id获取数据
export function getChildren(dataList, id) {
  let listObj = {}
  function findObj(targetData) {
    for (let i = 0; i < targetData.length; i++) {
      if (targetData[i].id === id) {
        listObj = {
          label: targetData[i].depName,
          value: targetData[i].id
        }
        break
      } else {
        if (targetData[i].children && targetData[i].children.length > 0) {
          findObj(targetData[i].children, id)
        }
      }
    }
  }
  findObj(dataList)
  return listObj
}
