<template>
  <a-modal
    placement="right"
    :visible="visible"
    :mask-closable="false"
    :after-close="afterVisibleChange"
    width="915px"
    @cancel="onClose"
    @ok="onSubMin"
  >
    <template slot="title">
      <span class="modal_title">表头设置</span>
    </template>
    <div>
      <span class="info">已选择表头</span>
      <span class="tip">可拖拽调整表头显示顺序，其中【姓名/工号/部门/岗位/考勤确认】为固定顺序不受拖拽影响</span>
      <div class="list_margin">
        <vueDragGable v-model="displayDragList" class="wrapper" :move="onMove" @end="end">
          <transition-group>
            <a-button
              v-for="item in displayDragList"
              :key="item.id"
              :is-blur="false"
              class="not_shown_class"
              type="primary"
              :disabled="item.d"
              @click="getDisplayList(item)"
            >
              {{ item.name }}
              <svg v-if="!item.d" class="iconpark-icon"><use href="#close-small" /></svg>
            </a-button>
          </transition-group>
        </vueDragGable>
      </div>
    </div>
    <div style="margin-top: 44px">
      <span class="info">未选择信息</span>
      <!--  内容区  -->
      <div class="list_margin">
        <a-button
          v-for="item in notShownListNull"
          :key="item.id"
          :is-blur="false"
          class="not_shown_class"
          @click="getNotShownList(item)"
        >
          {{ item.name }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import vueDragGable from './dragable'
export default {
  name: 'InfoSearch',
  components: {
    vueDragGable
  },
  data() {
    return {
      visible: false,
      notShownListNull: [],
      displayDragList: [],
      oldDisplayList: [],
      doNotDragList: []
    }
  },
  mounted() {
    // 阻止在火狐浏览器中拖拽表头时打开新页签
    document.body.ondrop = function(event) {
      event.preventDefault()
      event.stopPropagation()
    }
  },
  methods: {
    show(data) {
      this.visible = true
      this.notShownListNull = JSON.parse(JSON.stringify(data.notShownList))
      this.displayDragList = JSON.parse(JSON.stringify(data.displayList))
      this.oldDisplayList = JSON.parse(JSON.stringify(data.displayList))
      this.doNotDragList = JSON.parse(JSON.stringify(data.doNotDragList))
    },
    // 取消
    onClose() {
      this.visible = false
      this.notShownListNull = []
      this.displayDragList = []
      this.oldDisplayList = []
    },
    // 监听抽屉 true false
    afterVisibleChange(val) {
      if (!val) {
        this.onClose()
      }
    },
    // 结束拖拽
    end() {
      const arr = this.displayDragList.filter(item => item.disable === true)
      const arrList = this.displayDragList
      let status = true
      for (let i = 0; i < arr.length; i++) {
        if (!arrList[i].d) {
          status = false
          break
        }
      }
      if (!status) {
        this.displayDragList = this.oldDisplayList
      } else {
        this.oldDisplayList = this.displayDragList
      }
    },
    // 显示内容点击拖拽
    getDisplayList(val) {
      this.$set(this, 'displayDragList', this.displayDragList.filter(item => item.id !== val.id))
      this.notShownListNull.push(val)
    },
    // 未显示内容点击
    getNotShownList(val) {
      this.$set(this, 'notShownListNull', this.notShownListNull.filter(item => item.id !== val.id))
      this.displayDragList.push(val)
    },
    // 确认
    onSubMin() {
      const noDisplayArr = this.notShownListNull
      this.$emit('infoSearchCallback', this.displayDragList, noDisplayArr)
      this.onClose()
    },
    // 固定表头不可拖动
    onMove(e) {
      console.log('开始拖动')
      if (e.relatedContext.element.id === this.doNotDragList[0] || e.relatedContext.element.id === this.doNotDragList[1] || e.relatedContext.element.id === this.doNotDragList[2] || e.relatedContext.element.id === this.doNotDragList[3]) { return false } else { return true }
    }
  }
}
</script>

<style lang="less" scoped>
  .modal_title {
    font-size: 16px;
    font-weight: normal;
  }
  .modal_tip {
    font-size: 12px;
    font-weight: normal;
    color: @sc-grey-60;
    margin-left: 5px;
  }
  .screen_button {
    position: fixed;
    right: 20px;
    bottom: 30px;
    .close_button {
      margin-right: 10px;
    }
  }
  .info {
    font-size: 16px;
    color: @sc-grey-100;
    margin: 0 15px 0 5px;
  }
  .tip{
    font-size: 12px;
    color: @sc-grey-100;
  }
  .list_margin{
    margin: 15px 0;
    .not_shown_class {
      margin: 10px 10px 0 0;
      .iconpark-icon{
        margin-left: 4px;
      }
    }
  }
</style>
