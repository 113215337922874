import { DISTRIBUTION } from '@/services/api'
import { request, METHOD } from '@/utils/request'

// 人数分配
// 获取集团Licenses使用情况
export async function getLicensesUsage(params) {
  return request(`${DISTRIBUTION}/licensesUsage`, METHOD.GET, params)
}

// 分配租户Licenses
export async function setTenantLicenses(params) {
  return request(`${DISTRIBUTION}/setTenantLicenses`, METHOD.POST, params)
}
// 是否展示分配人数入口
export async function getLicensesMenuStatus(params) {
  return request(`${DISTRIBUTION}/getLicensesMenuStatus`, METHOD.GET, params)
}
