<!--
 * @Author: luoyi
 * @Date: 2022-02-22 10:32:12
 * @Last Modified by:   luoyi
 * @Last Modified time: 2022-02-22 10:32:12
-->
<template>
  <ul ref="scmenu" class="sc-menu" :style="`height:calc( 100% - ${offsetTop}px )`">
    <template v-for="(item, index) in dataList">
      <!--普通菜单项-->
      <scMenuItem
        v-if="!item[replaceFields.children]"
        :key="item[replaceFields.key]"
        :menu-data="item"
        :indent="indent"
        :inlevel="inlevel"
        :in-index="index + 1"
        :selected-key.sync="selectedKeyInner"
        :selected-item.sync="selectedItem"
      >
        <slot
          name="menuItem"
          :data="{
            info: item,
            path: `${inPath == '' ? index + 1 : inPath + '-' + (index + 1)}`
          }"
        />
      </scMenuItem>
      <!--包含子集的菜单项-->
      <scSubMenu
        v-if="item[replaceFields.children]"
        :key="item[replaceFields.key]"
        :menu-data="item"
        :indent="indent"
        :inlevel="inlevel"
        :in-index="index + 1"
        :in-path="`${inPath == '' ? index + 1 : inPath + '-' + (index + 1)}`"
        :selected-key.sync="selectedKeyInner"
        :selected-item.sync="selectedItem"
      >
        <!-- 将外部配置的插槽传入内部 -->
        <template v-for="(ind, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </scSubMenu>
    </template>
  </ul>
</template>

<script>
export default {
  name: 'ScMenu',
  components: {
    scSubMenu: () => import('./submenu.vue'),
    scMenuItem: () => import('./menuItem.vue')
  },
  props: {
    dataList: {
      type: Array,
      default: function() {
        return []
      }
    },
    indent: {
      // 菜单缩进宽度
      type: [Number, String],
      default: 24
    },
    selectedKey: {
      // 选中的菜单项key
      type: [Number, String],
      default: ''
    },
    replaceFields: {
      // 替换dataList中key,children字段为replaceFields的属性
      type: Object,
      default: function() {
        return { children: 'children', key: 'id' }
      }
    },
    inlevel: {
      // 内置层级，不需要传参
      type: Number,
      default: 1
    },
    inPath: {
      // 内置序号，不需要传参
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedKeyInner: this.selectedKey,
      selectedItem: {},
      offsetTop: 0
    }
  },
  watch: {
    'selectedKey': {
      handler: function(val) {
        // console.log('menuchange', val)
        this.selectedKeyInner = val
      },
      immediate: true
    },
    selectedKeyInner: function(val) {
      if (val) {
        this.$emit('update:selectedKey', val)
        // 当selectedItem的key和selectedKey不一致时，手动清空选择内容，否则出发change回调
        if (
          this.selectedItem[this.replaceFields.key] &&
          this.selectedItem[this.replaceFields.key] === val
        ) {
          this.$emit('change', this.selectedItem)
        } else {
          this.selectedItem = {}
        }
      }
    }
  },
  mounted() {
    this.offsetTop = this.$refs.scmenu.offsetTop
    console.log(this.dataList)
  }
}
</script>

<style style="less" scoped>
.sc-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
}
</style>
