/* eslint-disable no-extend-native */
var floatTool = (function() {
  /*
   * 判断obj是否为一个整数
   */
  function isInteger(obj) {
    return Math.floor(obj) === obj
  }

  /*
   * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
   * @param floatNum {number} 小数
   * @return {object}
   *   {times:100, num: 314}
   */
  function toInteger(floatNum) {
    var ret = { times: 1, num: 0 }
    if (isInteger(floatNum)) {
      ret.num = floatNum
      return ret
    }
    var strfi = floatNum + ''
    var dotPos = strfi.indexOf('.')
    var len = strfi.substr(dotPos + 1).length
    var times = Math.pow(10, len)
    // var intNum = parseInt(floatNum * times + 0.5, 10);//感觉这句怪怪的，修改为移动小数点
    var intNum = parseInt(strfi.movePoint(len), 10)
    ret.times = times
    ret.num = intNum
    return ret
  }

  /*
   * 核心方法，实现加减乘除运算，确保不丢失精度
   * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
   *
   * @param a {number} 运算数1
   * @param b {number} 运算数2
   * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数
   * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
   *
   */
  function operation(a, b, op) {
    var o1 = toInteger(a)
    var o2 = toInteger(b)
    var t1 = o1.times
    var t2 = o2.times
    var n1 = o1.num
    var n2 = o2.num
    var max = t1 > t2 ? t1 : t2
    var result

    switch (op) {
      case 'add':
        if (t1 === t2) { // 两个小数位数相同
          result = n1 + n2
        } else if (t1 > t2) { // o1 小数位 大于 o2
          result = n1 + n2 * (t1 / t2)
        } else { // o1 小数位 小于 o2
          result = n1 * (t2 / t1) + n2
        }
        return result / max
      case 'subtract':
        if (t1 === t2) {
          result = n1 - n2
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2)
        } else {
          result = n1 * (t2 / t1) - n2
        }
        return result / max
      case 'multiply':
        result = (n1 * n2) / (t1 * t2)
        return result
      case 'divide':
        var r1 = n1 / (n2 === 0 ? 1 : n2)
        var r2 = t2 / t1
        return operation(r1, r2, 'multiply')
    }
    return null
  }

  // 加减乘除的四个接口
  function add(a, b) {
    return operation(a, b, 'add')
  }

  function subtract(a, b) {
    return operation(a, b, 'subtract')
  }

  function multiply(a, b) {
    return operation(a, b, 'multiply')
  }

  function divide(a, b) {
    return operation(a, b, 'divide')
  }

  // exports
  return {
    add: add,
    subtract: subtract,
    multiply: multiply,
    divide: divide
  }
}())
/**
* 左补齐字符串
*
* @param nSize
*            要补齐的长度
* @param ch
*            要补齐的字符
* @return
*/
String.prototype.padLeft = function(nSize, ch) {
  var len = 0
  var s = this ? this : ''
  ch = ch || '0' // 默认补0

  len = s.length
  while (len < nSize) {
    s = ch + s
    len++
  }
  return s
}

/**
* 右补齐字符串
*
* @param nSize 要补齐的长度
* @param ch 要补齐的字符
* @return
*/
String.prototype.padRight = function(nSize, ch) {
  var len = 0
  var s = this
  ch = ch || '0' // 默认补0

  len = s.length
  while (len < nSize) {
    s = s + ch
    len++
  }
  return s
}
/**
* 左移小数点位置（用于数学计算，相当于除以Math.pow(10,scale)）
* @param scale 要移位的刻度
* @return
*/
String.prototype.movePointLeft = function(scale) {
  var s, s1, s2, ch, ps, sign
  ch = '.'
  sign = ''
  s = this

  if (scale <= 0) return s
  ps = s.split('.')
  s1 = ps[0] ? ps[0] : ''
  s2 = ps[1] ? ps[1] : ''
  if (s1.slice(0, 1) === '-') {
    s1 = s1.slice(1)
    sign = '-'
  }
  if (s1.length <= scale) {
    ch = '0.'
    s1 = s1.padLeft(scale)
  }
  return sign + s1.slice(0, -scale) + ch + s1.slice(-scale) + s2
}
/**
* 右移小数点位置（用于数学计算，相当于乘以Math.pow(10,scale)）
* @param scale  要移位的刻度
* @return
*/
String.prototype.movePointRight = function(scale) {
  var s, s1, s2, ch, ps
  ch = '.'
  s = this

  if (scale <= 0) return s
  ps = s.split('.')
  s1 = ps[0] ? ps[0] : ''
  s2 = ps[1] ? ps[1] : ''
  if (s2.length <= scale) {
    ch = ''
    s2 = s2.padRight(scale)
  }
  return s1 + s2.slice(0, scale) + ch + s2.slice(scale, s2.length)
}
/**
* 移动小数点位置（用于数学计算，相当于（乘以/除以）Math.pow(10,scale)）
* @param scale  要移位的刻度（正数表示向右移；负数表示向左移动；0返回原值）
* @return
*/
String.prototype.movePoint = function(scale) {
  if (scale >= 0) { return this.movePointRight(scale) } else { return this.movePointLeft(-scale) }
}

/**
* toFixed修复
* @param {} scale
* @returns {}
*/
Number.prototype.toFixed = function(scale) {
  var s, s1, s2, start

  s1 = this + ''
  start = s1.indexOf('.')
  s = s1.movePoint(scale)

  if (start >= 0) {
    s2 = Number(s1.substr(start + scale + 1, 1))
    if (s2 >= 5 && this >= 0 || s2 < 5 && this < 0) {
      s = Math.ceil(s)
    } else {
      s = Math.floor(s)
    }
  }
  var ss = s.toString().movePoint(-scale)
  // console.log(this + "toFixed=" + ss);
  return ss
}

export default floatTool
