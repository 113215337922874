<template>
  <div class="info-set">
    <a-popover
      v-model="propoVisible"
      trigger="click"
      placement="bottomRight"
      :align="{offset: [4, 5]}"
      :auto-adjust-overflow="false"
      overlay-class-name="info-popover"
      :get-popup-container="(triggerNode) => triggerNode.parentNode"
    >
      <template slot="content">
        <div class="menu-list">
          <!-- 头像 姓名 账号权限 -->
          <div class="person-info">
            <div class="head-portrait">
              <CbAvater
                :on-job-id="accountInfo.onJobId"
                :avater-name="accountInfo.staffName?accountInfo.staffName:accountInfo.userName?accountInfo.userName:''"
                size="36"
              />
            </div>
            <div class="Avatar-tags">
              <a-tag v-if="accountInfo.userType === '0'">普通账号</a-tag>
              <a-tag v-if="accountInfo.userType === '1'">超级管理员</a-tag>
            </div>
          </div>
          <!-- 切换企业 -->
          <div v-if="tenants.length > 1" class="function-setting switch-enterprise">
            <a-popover
              trigger="hover"
              placement="leftTop"
              overlay-class-name="tenant-popover"
              :align="{offset: [2, 0]}"
            >
              <template slot="content">
                <div v-for="item in tenants" :key="item.tenantId" :class="[item.tenantId === currentId ? 'content-p active-content' : 'content-p']" @click="changeCompony(item.tenantId)">
                  <a-tooltip placement="left">
                    <template slot="title">
                      <span>{{ item.tenantName }}</span>
                    </template>
                    <p>{{ item.tenantName }}</p>
                  </a-tooltip>
                </div>
              </template>
              <p>切换企业<a-icon type="right" /></p>
            </a-popover>
          </div>
          <div v-if="accountInfo.userType === '1' && isShow === true" class="personnel">
            <div class="personnal" @click="personelModal">
              <p>人数分配</p>
            </div>
          </div>
          <!-- 修改密码 -->
          <div class="function-setting">
            <p @click="updatePassword">修改密码</p>
            <PwdDrawer ref="pwdDrawer" />
          </div>
          <DistributionModal ref="distribution" />
        </div>
        <!-- 安全退出 -->
        <div class="function-setting loginBox">
          <p class="login-out" @click="loginOut">安全退出</p>
        </div>
      </template>
      <slot name="avaterPopover" />
    </a-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { doLogout, loginTenant } from '@/services/login'
import { getCurrentUserTenantList } from '@/services/oneSelfSetting/oneSelfSetting.js'
import { getLicensesMenuStatus } from '@/services/oneSelfSetting/numberDistribution.js'
import { afterLogin } from '@/pages/login/components/publicMixins.js'
import { removeAuthorization } from '@/utils/request'

export default {
  name: 'Header',
  components: {
    DistributionModal: () => import('./distributionModal.vue'),
    PwdDrawer: () => import('./pwdDrawer.vue'),
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  mixins: [afterLogin],
  props: {
    accountInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      propoVisible: false,
      isShow: undefined,
      tenants: [],
      currentId: ''
    }
  },
  computed: {
    ...mapGetters('account', ['tenant'])
  },
  watch: {
    propoVisible() {
      this.$nextTick(() => {
        const popOverContentTag = document.querySelector('.info-popover .ant-popover-inner-content')
        popOverContentTag.style.padding = '0 0 10px'
      })
    },
    tenant: {
      immediate: true,
      deep: true,
      handler: function(newVal) {
        this.currentId = this.tenant ? this.tenant.tenantId : ''
      }
    }
  },
  created() {
    this.getUserTenantList()
  },
  mounted() {
    getLicensesMenuStatus().then((res) => {
      if (res.code === 100) {
        this.isShow = res.data
      }
    })
  },
  methods: {
    // 打开气泡层
    open() {
      this.propoVisible = true
    },
    // 获取租户列表
    async getUserTenantList() {
      const res = await getCurrentUserTenantList()
      this.tenants = res.data
      this.currentId = this.tenant ? this.tenant.tenantId : ''
    },
    // 人数分配
    personelModal() {
      this.$refs.distribution.open()
      this.propoVisible = false
    },
    // 修改密码
    updatePassword() {
      this.$refs.pwdDrawer.passwordVisible = true
      this.propoVisible = false
    },
    // 切换企业
    changeCompony(val) {
      loginTenant({
        tenantId: val
      }).then(res => {
        if (res.code === 100) {
          this.propoVisible = false
          this.afterLogin(res.data)
          if (this.$route.path === '/home') {
            window.location.reload()
          } else {
            window.location.href = '/home'
          }
          this.$message.success('切换企业成功')
        }
      }).catch(err => err)
    },
    loginOut() {
      this.propoVisible = false
      this.$Reconfirm({
        title: '提示',
        content: '确认退出当前账号吗？'
      }).then(() => {
        doLogout().then(() => {
          localStorage.clear()
          removeAuthorization()
          this.$router.push('/login')
        })
      }).catch(() => {
        console.log('no')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.menu-list{
  min-width: 246px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: @sc-grey-100;
}
.person-info{
  padding: 16px 14px 0;
  display: flex;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head-portrait{
  margin-right: 6px;
}

.Avatar-tags {
  .ant-tag{
    margin-right: 0;
    border: 0;
    border-radius: 2px;
    padding: 2px 6px;
    color: @sc-grey-60;
  }
}
.Avatar-name{
  margin-bottom: 6px;
}
.function-setting{
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin:0 8px;
  &:hover{
    background: @sc-greyBg-10;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
  p{
    margin: 0;
    padding: 0 8px;
  }
}
.personnel{
  position: relative;
  cursor: pointer;
  .personnal{
    height: 40px;
    line-height: 40px;
    margin:0 8px;
  }
  .personnal:hover{
    background: @sc-greyBg-10;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
  p{
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    .anticon-right{
      color: @sc-greyBg-100;
    }
  }
}
.switch-enterprise{
  p{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .anticon-right{
      color: @sc-greyBg-100;
    }
  }
}
.ant-popover{
  .content-p{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .font-size(14px);
    padding: 10px;
    margin-bottom: 10px;
    color: @sc-grey-100;
    p{
      margin-bottom: 0;
      .multi-ellipsis(1);
      white-space: break-spaces;
      word-break: break-all;
      .ant-tag{
        margin: 0;
        margin-top: 8px;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
    &:hover{
      background: @sc-greyBg-10;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      color: @primary-color;
    }
  }
  .active-content{
    background: @sc-greyBg-10;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: @primary-color;
  }
}
.login-out{
  color: @error-color;
}
.login-modal{
  /deep/.ant-modal-header{
    background: @gray-1;
  }
  /deep/.ant-modal-body{
    padding: 20px 24px 14px;
  }
}
</style>
<style lang="less">
// 全局气泡窗投影
.ant-popover-inner{
  box-shadow: 0px 14px 28px 0px rgba(41, 55, 100, 0.09) !important;
}
.info-popover{
  min-width: 246px;
}
.tenant-popover {
  .ant-popover-content{
    max-width: 236px;
  }
  .ant-popover-inner-content{
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar{
      width: 4px;
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: @sc-greyBg-20;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0,0,0,0);
      border-radius: 3px;
      background: #fff;
    }
  }
}
</style>

