<!--
 * @Date: 2022-05-28 10:26:14
 * @descriotion:
-->
<template>
  <div class="content">
    <a-row type="flex" justify="space-between">
      <a-col :span="8">
        <slot name="leftContent" />
      </a-col>
      <a-col class="dateMonth" :span="8">
        <svg class="iconpark-icon left-icon" @click="prevMonth"><use href="#left" /></svg>
        <!-- <a-icon
          type="left"
          class="left-icon"
          @click="prevMonth"
        /> -->
        {{ current.year }}年 {{ current.month + 1 }}月

        <span v-if="current.year === year && current.month === month && !future ">
          <!-- <a-icon type="right" class="right-icon-no right-icon" /> -->
          <svg class="iconpark-icon right-icon-no right-icon"><use href="#left" /></svg>
        </span>
        <span v-else @click="nextMonth">
          <!-- <a-icon type="right" class="right-icon" /> -->
          <svg class="iconpark-icon right-icon"><use href="#left" /></svg>
        </span>
      </a-col>
      <a-col :span="8">
        <slot name="rightContent" />
      </a-col>
    </a-row>
    <div>
      <slot name="bottomContent" />
    </div>
    <ul class="header header-bg">
      <li v-for="item in weekdaysShort" :key="item">
        <p class="title">{{ item }}</p>
      </li>
    </ul>
    <ul class="header list beauty-scroll" :style="`height:${listHeight}px`">
      <li v-for="(item,index) in days_array" :key="index" :class="item.disable?'disable':''" class="calendar">
        <p class="text">{{ item.value | dateStr }}</p>
        <div>
          <slot :name="item.value" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'
const year = new Date().getFullYear()
const month = new Date().getMonth()
const startDate = new Date(year, month, 1)
const endDate = new Date(year, month + 1, 0)
export default {
  name: 'Calendar',
  filters: {
    dateStr(str) {
      return moment(str).format('MM-DD')
    }
  },
  props: {
    startDate: {
      type: String,
      default: moment(startDate).format('YYYY-MM-DD')
    },
    endDate: {
      type: String,
      default: moment(endDate).format('YYYY-MM-DD')
    },
    // 是否选择未来月份
    future: {
      type: Boolean,
      default: true
    },
    defaultMonth: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      current: {},
      weekdaysShort: ['周 一', '周 二', '周 三', '周 四', '周 五', '周 六', '周 日'],
      days_array: [],
      year: year,
      month: month,
      listHeight: 0
    }
  },
  watch: {
    endDate(newVal) {
      this.setCalendarData(this.startDate, newVal)
    },
    defaultMonth(newVal) {
      this.setCurrent(newVal)
    }
  },
  mounted() {
    this.setCurrent()
    if (!this.startDate || !this.endDate) {
      this.setCalendarData(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    } else {
      this.setCalendarData(this.startDate, this.endDate)
    }
    this.$nextTick(() => {
      const el = document.querySelector('.list')
      const contentHeight = document.querySelector('.content').clientHeight
      console.log(el.offsetTop)
      const num = this.future ? 68 : 128
      this.listHeight = contentHeight - el.offsetTop - num
    })
  },
  methods: {
    setCurrent(d = new Date()) {
      const year = d.getFullYear()
      const month = d.getMonth()
      const date = d.getDate()
      this.current = {
        year,
        month,
        date
      }
    },
    // 日期间的所有日期
    setCalendarData(startDate, endDate) {
      const oneDayMS = 24 * 60 * 60 * 1000
      const startTime = new Date(startDate.replaceAll('-', '/'))
      const endTime = new Date(endDate.replaceAll('-', '/'))
      const dateArr = []
      // 计算startDate周几 补全当前周
      const firstDay = startTime.getDay()
      const prefixDaysLen = firstDay === 0 ? 6 : firstDay - 1
      const bengin = new Date(moment(startTime.getTime() - prefixDaysLen * oneDayMS).format('YYYY/MM/DD'))

      // 计算endDate周几 补全当前周
      const lastDay = endTime.getDay()
      const suffixDatsLen = lastDay === 0 ? 0 : 7 - lastDay
      const end = new Date(moment(endTime.getTime() + suffixDatsLen * oneDayMS).format('YYYY/MM/DD'))
      while (end.getTime() - bengin.getTime() >= 0) {
        const year = bengin.getFullYear()
        const month = bengin.getMonth() + 1
        const day = bengin.getDate()
        const obj = {
          date: new Date(year + '/' + month + '/' + day),
          value: moment(year + '/' + month + '/' + day).format('YYYYMMDD'),
          disable: bengin.getTime() < startTime.getTime() || bengin.getTime() > endTime.getTime()
        }
        dateArr.push(obj)
        bengin.setDate(bengin.getDate() + 1)
      }
      this.days_array = dateArr
    },
    // 判断当前月有多少天
    getDaysByMonth(year, month) {
      return new Date(year, month + 1, 0).getDate()
    },
    // 点击上一月
    prevMonth() {
      this.current.month--
      // 因为 month的变化 会超出 0-11 的范围， 所以需要重新计算
      this.correctCurrent()
    },
    // 点击下一月
    nextMonth() {
      this.current.month++
      // 因为 month的变化 会超出 0-11 的范围， 所以需要重新计算
      this.correctCurrent()
    },
    correctCurrent() {
      const { year, month, date } = this.current
      const maxDate = this.getDaysByMonth(year, month)
      // 预防其他月跳转到2月，2月最多只有29天，没有30-31
      const day = Math.min(maxDate, date)
      const instance = new Date(year, month, day)
      this.setCurrent(instance)
      this.$emit('onChange', instance)
    }
  }
}
</script>
<style lang="less" scoped>
::-webkit-scrollbar {display:none}
.content{
  height:100%;
  background: #fff;
}
.header-bg{
  width:100%;
  height:42px;
  background:rgba(143, 147, 161, 0.08);
  align-items: center;
  border-bottom: 1px solid #DFE2ED;
}
ul{
  margin: 0;
}
.header{
  display: flex;
  flex-wrap: wrap;

  li{
    width:calc(100%/7);
    color:rgba(32, 42, 64, 0.800000011920929);
    .title{
      text-align: center;
      font-size:16px;
    }
    .text{
      text-align: right;
      font-size:16px;
      margin-right:20px;
    }
    p{
      margin:0;
    }
  }
  .disable{
    color: @sc-grey-20;
    background: rgba(143, 147, 161, 0.08);
  }
  .calendar{
    padding:20px 0;
    height:186px;
    border-collapse: collapse;
    border-bottom: 1px solid #DFE2ED;
    border-left: 1px solid #DFE2ED;
    text-align: right;
  }
  .calendar:nth-child(7n){
    border-right: 1px solid #DFE2ED;
  }
}
.dateMonth{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:16px;
  font-weight: 700;
  color:@sc-grey-100;
  .iconpark-icon{
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
}
.right-icon{
  margin-left: 34px;
  cursor: pointer;
  transform: rotate(180deg);
}
.right-icon-no{
  color: @sc-grey-40
}
.left-icon{
  margin-right: 34px;
}
.date{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:50px;
  .col{
    display: flex;
    align-items: center;
  }
  p{
    margin:0;
  }
}
</style>
