import { ONESELF_SETTING } from '@/services/api'
import { request, METHOD } from '@/utils/request'

// 获取个人信息
export async function getAccountInfo(params) {
  return request(`${ONESELF_SETTING}/getAccountInfo`, METHOD.GET, params)
}

// 获取当前用户的租户列表
export async function getCurrentUserTenantList(params) {
  return request(`${ONESELF_SETTING}/getUserTenantListByUserId`, METHOD.GET, params)
}
