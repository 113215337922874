<template>
  <div class="DepTreeSelect">
    <a-tree-select
      v-model="value"
      :default-value="value"
      :tree-data="treeData"
      :replace-fields="replaceFields"
      :allow-clear="allowClear"
      :tree-checkable="multiple"
      tree-check-strictly
      :disabled="disabled"
      :placeholder="placeholder"
      tree-node-filter-prop="depName"
      :style="{width: selectWidth}"
      :search-value="''"
      :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
      @change="treeSelect"
      @blur="selectBlur(value)"
    />
    <a-icon v-if="multiple" type="down" class="icon" />
  </div>
</template>

<script>
import { getOrgTree } from '@/services/dropDownInput/index.js'
import { getChildren } from './index.js'
export default {
  name: 'DepTreeSelect',
  model: {
    prop: 'treeSelectValue',
    event: 'getValueSelect'
  },
  props: {
    treeDataList: {
      type: Array,
      default: () => []
    },
    treeSelectValue: {
      type: [Array, String],
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    selectWidth: {
      type: String,
      default: '100%'
    },
    queryLeavePerson: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: [],
      treeData: [],
      replaceFields: {
        title: 'depName',
        key: 'id',
        value: 'id'
      }
    }
  },
  watch: {
    treeSelectValue(val) {
      this.value = val
      this.echoFunc()
    }
  },
  mounted() {
    this.getOrgTree()
    this.$nextTick(() => {
      this.echoFunc()
    })
  },
  methods: {
    // 回显的方法
    echoFunc() {
      if (!this.multiple) {
        this.value = []
        if (typeof this.treeSelectValue === 'string') {
          this.value = this.treeSelectValue
          this.$emit('echoPost')
        } else if (this.treeSelectValue && this.treeSelectValue.length > 0) {
          for (let i = 0; i < this.treeSelectValue.length; i++) {
            this.value.push(this.treeSelectValue[i].value)
          }
        }
      } else {
        this.value = this.treeSelectValue
      }
    },
    async getOrgTree() {
      if (this.treeDataList && this.treeDataList.length > 0) {
        this.treeData = this.treeDataList
      } else {
        const res = await getOrgTree({ nested: true, scope: false, leaveStaff: this.queryLeavePerson })
        this.treeData = this.orgTreeHandel(res.data[0].children)
      }
    },
    orgTreeHandel(data) {
      data.forEach(item => {
        item['depName'] = item.orgName
        item['id'] = item.orgId
        if (item.children && item.children.length > 0) {
          this.orgTreeHandel(item.children)
        }
      })
      return data
    },
    treeSelect(data) {
      if (this.multiple) {
        this.$emit('getValueSelect', data)
      } else {
        if (data === undefined) {
          this.$emit('getValueSelect', data)
        } else {
          const arr = getChildren(this.treeData, data)
          this.$emit('getValueSelect', [arr])
        }
      }
    },
    selectBlur(data) {
      this.$emit('getSelectBlur', data)
    }
  }
}
</script>

<style lang="less" scoped>
  .DepTreeSelect {
    position: relative;
    width: 100%;
  }
  .icon {
    position: absolute;
    top: 15px;
    right: 10px;
    color: #cccccc;
  }
  /deep/ .ant-select-search__field__placeholder {
    cursor: pointer;
  }
</style>
